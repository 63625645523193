import { stage } from './environment';

export enum toggleNames {
  REGISTRATION = 'registration',
  FAMILY_REGISTRATION = 'familyRegistration',
  EVENT_REGISTRATION_WEEKS = 'eventRegistrationWeeks',
  COVID = 'covid',
  ACCOUNT = 'account',
  SMS = 'sms',
  ASC = 'allSchoolsChampionships',
  WC = 'winterChampionships',
  RR = 'rockettRelays',
  PAYMENTS = 'payments',
  SQUARE_PAYMENTS = 'squarePayments',
  FACEBOOK_SIGNIN = 'facebookSignIn',
}

type ToggleResponse = boolean | number;
export const toggles: { [key in toggleNames]: ToggleResponse } = {
  [toggleNames.ACCOUNT]: false, //stage !== 'production'
  [toggleNames.COVID]: true, //stage !== 'production'
  [toggleNames.REGISTRATION]: false, //stage !== 'production'
  [toggleNames.FAMILY_REGISTRATION]: false, //stage !== 'production'
  [toggleNames.EVENT_REGISTRATION_WEEKS]: stage === 'production' ? 3 : 24,
  [toggleNames.SMS]: false, //stage !== 'production'
  [toggleNames.ASC]: false,
  [toggleNames.RR]: false,
  [toggleNames.WC]: true,
  [toggleNames.PAYMENTS]: false,
  [toggleNames.SQUARE_PAYMENTS]: false,
  [toggleNames.FACEBOOK_SIGNIN]: false
};

