import {
    Accounts,
    Administrators,
    AppNav,
    Calendar,
    Config,
    CompetitionPage,
    Home,
    Profile,
    Runners,
} from '@containers';
import React, {Component} from 'react';
import {compose, withProps} from 'recompose';
import {BrowserRouter as Router, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {RouteKeys, Routes} from '@constants/routes';
import {Container} from '@material-ui/core';
import styled from 'styled-components';
import {ApolloClient, ApolloProvider, NormalizedCacheObject} from '@apollo/client';
import {ROLE} from './@utils/role';
import {SnackbarProvider} from 'notistack';
import {AppFooter} from './@containers/partials/app-footer';
import UserContext from './UserContext';
import {createClient} from './graphql-client';
import Authenticator from './Authenticator';
import {adminTheme, ErrorComponent, ThemeProvider} from '@queensland-running/qr-components';
import {RouteProps} from 'react-router';
import ConfigProvider from '@constants/config';

type AppProps = {};
type AppViewProps = AppProps & {
    client: ApolloClient<NormalizedCacheObject>;
};

const enhance = compose<AppViewProps, AppProps>(
    withProps<{}, AppViewProps>(() => {
        return {
            client: createClient(),
        };
    }),
    React.memo,
);
const StyledContainer = styled('div')`
  @media only screen and (min-width: ${({theme}) => theme.breakpoints.values.xs}px) {
    padding-top: 4rem;
    padding-bottom: 30rem;
  }

  @media only screen and (min-width: ${({theme}) => theme.breakpoints.values.md}px) {
    padding-top: 4rem;
    padding-bottom: 20rem;
  }

  @media only print {
    padding-left: 0 !important;
    padding-right: 0 !important;
    @page {
      size: portrait;
      td,th, p {
        font-size: 9pt !important;
        page-break-after: avoid;
      }
    }
  }
`;

type PrivateRouteProps = RouteProps & { role?: ROLE };
type PrivateRouteState = { loaded: boolean; isAuthenticated: boolean };

class PrivateRouteClass extends Component<PrivateRouteProps, PrivateRouteState> {
    state = {
        loaded: false,
        isAuthenticated: false,
    };
    static contextType = UserContext;

    componentDidMount() {
        // @ts-ignore
        this.unlisten = this.props.history.listen(() => {
            this.context.updateCurrentUser();
        });
    }

    componentWillUnmount() {
        // @ts-ignore
        this.unlisten();
    }

    render() {
        // @ts-ignore
        const {component: Component, role, ...rest} = this.props;
        const isAuthenticated = this.context.user && this.context.user.username ? true : false;
        const isLoaded = this.context.isLoaded;

        if (!isLoaded) return null;

        if (role && this.context.user && !this.context.user.roles.includes(role)) {
            return (
                <ErrorComponent
                    title="Access forbidden"
                    message="You tried to access a page you did not have prior authorization for. If you believe this to be a mistake, contact the administrator."
                />
            );
        }

        return (
            <Route
                {...rest}
                render={(props) => {
                    return isAuthenticated ? (
                        // @ts-ignore
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/auth',
                            }}
                        />
                    );
                }}
            />
        );
    }
}

// const onRedirectCallback = (appState: any) => {
//   history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
// };

// @ts-ignore
const PrivateRoute = withRouter<PrivateRouteProps>(PrivateRouteClass);

// const StyledBanner = ({ children }) => <div style={{ backgroundColor: '#E0EE89', padding: '0.5rem' }}><Container><TypographyWithIcon variant='h2'><Warning fontSize='large'/>&nbsp;{children}</TypographyWithIcon></Container></div>

const AppView = ({client}: AppViewProps) => {
    return (
        <ConfigProvider>
            <ApolloProvider client={client}>
                <ThemeProvider theme={adminTheme}>
                    <SnackbarProvider>
                        <Router>
                            <div className="App" style={{position: 'relative', minHeight: '100vh'}}>
                                <AppNav/>
                                <StyledContainer>
                                    {/*<StyledBanner></StyledBanner>*/}
                                    <Container>
                                        <Switch>
                                            <Route exact path={'/auth'} component={Authenticator}/>
                                            <PrivateRoute exact path={Routes[RouteKeys.runners]} component={Runners}/>
                                            <PrivateRoute exact path={Routes[RouteKeys.config]} component={Config}/>
                                            <PrivateRoute
                                                exact
                                                role={ROLE.ADMINISTRATOR_MANAGER}
                                                path={Routes[RouteKeys.administrators]}
                                                component={Administrators}
                                            />
                                            <PrivateRoute
                                                exact
                                                role={ROLE.USER_MANAGER}
                                                path={Routes[RouteKeys.accounts]}
                                                component={Accounts}
                                            />
                                            <PrivateRoute exact path={Routes[RouteKeys.profile]} component={Profile}/>
                                            <PrivateRoute exact path={Routes[RouteKeys.competition]}
                                                          component={CompetitionPage}/>
                                            <PrivateRoute exact path={Routes[RouteKeys.calendar]} component={Calendar}/>
                                            <PrivateRoute path={Routes[RouteKeys.appBase]} component={Home}/>
                                        </Switch>
                                    </Container>
                                </StyledContainer>
                                <AppFooter/>
                            </div>
                        </Router>
                    </SnackbarProvider>
                </ThemeProvider>
            </ApolloProvider>
        </ConfigProvider>
    );
};

export const App = enhance(AppView);
