import React, {useContext, useState} from 'react';
import {compose} from 'recompose';
import queryString from 'query-string';
import {Competition, CompetitionRegistration} from '@generated/schema';
import {Grid, Typography} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {useFetchCompetitionQuery} from './queries.generated';
import {flow} from 'lodash/fp';
import UserContext from '../../../UserContext';
import {Button, ButtonWrapper, EventDate, EventProgram, LoadingComponent, NoPrint, PageTitle, Results,} from '@queensland-running/qr-components';
import {DeleteResultsModal} from '@containers/modals/delete-results-modal';
import {UploadResultsModal} from '@containers/modals/upload-results-modal';
import {ROLE} from '@utils/role';
import {UpdateEventModal} from '../../modals/update-event-modal';
import {ApolloError} from '@apollo/client';
import {useSnackbar} from 'notistack';
import {useDeclareWetWeatherMutation} from './mutations.generated';
import {withErrorBoundary} from '@components/higher-order';
import {UpdateRegistrationModal} from '../../modals/update-registration-modal';
// import useRegistrations from "../../../@components/higher-order/registrations";

type QueryParams = {
  eventId?: string;
};

const enhance = compose(withErrorBoundary, withRouter, React.memo);

const Banner = ({ event, eventHasPassed }: { event: Competition; eventHasPassed: boolean }) => {
  let message = undefined;

  // const isCancelled = event.status === 'CANCELLED';
  // const isWetWeather = event.status === 'WET_WEATHER';
  //
  // if (eventHasPassed) {
  //   message = 'This event has passed.';
  // }
  //
  // if (isCancelled) {
  //   message = 'This event has been cancelled.';
  // }
  //
  // if (isWetWeather) {
  //   message = 'This event has been cancelled due to wet weather. An SMS notification has been set already.';
  // }

  // if (isCancelled || eventHasPassed) {
  //   return (
  //     <Blockquote color="primary">
  //       <TypographyWithIcon variant="subtitle1">
  //         <AccessAlarm />
  //         &nbsp;{message}
  //       </TypographyWithIcon>
  //     </Blockquote>
  //   );
  // }

  return null;
};

const EventDetailsView = ({ location: { search } }: RouteComponentProps) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let competitionId = flow(queryString.parse, ({ eventId }: QueryParams) => eventId)(search);
  // const { registrations } = useRegistrations(competitionId);
// console.log(registrations)
  // ROLES
  const isEventManager = user && user.roles.includes(ROLE.EVENT_MANAGER);
  const isResultsManager = user && user.roles.includes(ROLE.RESULTS_MANAGER);

  const [updateEventModalOpen, setUpdateEventModalOpen] = useState(false);
  const [updateCompetitionRegistration, setUpdateCompetitionRegistration] = useState<CompetitionRegistration | undefined>(undefined);
  const [uploadResultsModalOpen, setUploadResultsModalOpen] = useState(false);
  const [deleteResultsModalOpen, setDeleteResultsModalOpen] = useState(false);

  const [declareWetWeather] = useDeclareWetWeatherMutation();

  const { data, loading } = useFetchCompetitionQuery({
    errorPolicy: 'all',
    variables: {
      id: competitionId,
    },
    // onCompleted: ({event}: { event: Event }) => {
    //   if (!event) {
    //     enqueueSnackbar('Event does not exist', {
    //       variant: 'warning',
    //       persist: true,
    //       action: (key) => <Button onClick={() => closeSnackbar(key)}>Dismiss</Button>,
    //     });
    //   }
    // },
    onError: (error: ApolloError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        persist: true,
        action: (key) => <Button title='Dismiss' onClick={() => closeSnackbar(key)} />,
      });
    },
  });

  if (loading) {
    return <LoadingComponent />;
  }

  const fetchedEvent = data && data.competition;

  if (fetchedEvent) {
    const date = new Date(fetchedEvent.day);
    const eventHasPassed = new Date() > date;


    switch (fetchedEvent.__typename) {
      case "WeeklyCompetition":
        const { ...event } = fetchedEvent;
        return (
          <>
            <PageTitle title={`Event Details`} hideOnPrint={true} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <NoPrint>
                  <Typography variant="h2" color={'primary'} gutterBottom>
                    {date.getFullYear()} - {event.agenda}
                  </Typography>
                  <Banner event={event as Competition} eventHasPassed={eventHasPassed} />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <EventDate day={date} variant="subtitle1" />
                      {/*<EventVenue park={event.park} parkKey={event.parkKey} variant="subtitle1" />*/}
                      <EventProgram program={event.program} variant="subtitle1" />
                      {/*<EventFinishers finishers={event.finishers} variant="subtitle1" />*/}
                      {/*<EventCompetitors competitors={event.competitors} variant="subtitle1" />*/}
                    </Grid>
                    {isEventManager && (
                      <Grid item xs={12} sm={6}>
                        <ButtonWrapper>
                          <Button
                            title='Change Details'
                            color="secondary"
                            type="submit"
                            onClick={() => setUpdateEventModalOpen(true)}
                            fullWidth/>
                          <Button
                            title="Declare Wet Weather"
                            color="danger"
                            type="submit"
                            disabled={eventHasPassed || true}
                            onClick={() =>
                              declareWetWeather({
                                variables: {
                                  input: {
                                    eventId: fetchedEvent.id,
                                  },
                                },
                              })
                            }
                            fullWidth
                          />
                        </ButtonWrapper>
                      </Grid>
                    )}
                  </Grid>

                  {/*{event.notes && <Blockquote color="secondary">{event.notes}</Blockquote>}*/}
                </NoPrint>
                {/*{registeredAthletes && (*/}
                {/*  <RegisteredRunnersTable*/}
                {/*    runners={registeredAthletes!.data as RunnerDetails[]}*/}
                {/*    registrations={true}*/}
                {/*    isAdmin={true}*/}
                {/*    eventDate={event.day}*/}
                {/*    onClickRegistration={setUpdateCompetitionRegistration}*/}
                {/*  />*/}
                {/*)}*/}
              </Grid>
              <Grid item xs={12} md={4}>
                <NoPrint>
                  <Results
                    competition={event as Competition}
                    onClickUploadResults={
                      isResultsManager && event.status !== 'CANCELLED' ? () => setUploadResultsModalOpen(true) : undefined
                    }
                    onClickDeleteResults={
                      isResultsManager && event.status !== 'CANCELLED' ? () => setDeleteResultsModalOpen(true) : undefined
                    }
                  />

                  {/*{event.park && <VenueCard {...event.park} imageURL={event.park.imageURL || fallbackImg} />}*/}
                  {/*{registeredAthletes && (*/}
                  {/*  <ErrorBoundary>*/}
                      {/*<RunnerDetailsTotal*/}
                      {/*//@ts-ignore*/}
                      {/*  registrations={registeredAthletes.data}*/}
                      {/*/>*/}
                    {/*</ErrorBoundary>*/}
                  {/*)}*/}
                  <br />

                  {/*{registeredAthletes && (*/}
                  {/*  <ErrorBoundary>*/}
                  {/*    <RunnerDetailsGraphOverTime*/}
                  {/*      //@ts-ignore*/}
                  {/*      registrations={registeredAthletes.data} />*/}
                  {/*  </ErrorBoundary>*/}
                  {/*)}*/}
                  <br />

                  {/*{registeredAthletes && (*/}
                  {/*  <ErrorBoundary>*/}
                  {/*    <RunnerDetailsAgeGroups*/}
                  {/*      //@ts-ignore*/}
                  {/*      registrations={registeredAthletes.data} />*/}
                  {/*  </ErrorBoundary>*/}
                  {/*)}*/}
                  <br />
                  {/*{registeredAthletes && (*/}
                  {/*  <ErrorBoundary>*/}
                  {/*    <RunnerDetailsClubs*/}
                  {/*      //@ts-ignore*/}
                  {/*      registrations={registeredAthletes.data} />*/}
                  {/*  </ErrorBoundary>*/}
                  {/*)}*/}
                  <br />
                  {/*<Typography variant="h5" color={'secondary'}>*/}
                  {/*  {event.program}*/}
                  {/*</Typography>*/}
                  {/*<ProgramTable programItems={programItems as ProgramItem[]} />*/}
                </NoPrint>
              </Grid>
            </Grid>
            {updateEventModalOpen && (
              <UpdateEventModal
                event={event as Competition}
                open={updateEventModalOpen}
                onClose={() => setUpdateEventModalOpen(false)}
              />
            )}
            {!!updateCompetitionRegistration && (
              <UpdateRegistrationModal
                eventId={event.id}
                registration={updateCompetitionRegistration}
                open={!!updateCompetitionRegistration}
                onClose={() => setUpdateCompetitionRegistration(undefined)}
              />
            )}
            {uploadResultsModalOpen && (
              <UploadResultsModal
                eventId={event.id}
                open={uploadResultsModalOpen}
                onClose={() => setUploadResultsModalOpen(false)}
              />
            )}
            {deleteResultsModalOpen && (
              <DeleteResultsModal
                eventId={event.id}
                open={deleteResultsModalOpen}
                onClose={() => setDeleteResultsModalOpen(false)}
              />
            )}
          </>
        )
    }
    return <>Unsupported event</>
  } else {
    return <>Unable to load event</>;
  }
};

// @ts-ignore
export const EventDetailsPage = enhance(EventDetailsView);
