import React, { FunctionComponent, useContext } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { RouteKeys, Routes } from '@constants';
import { ROLE } from '@utils/role';
import UserContext from 'UserContext';
import { Contact } from '@components/contacts';
import { PageTitle } from '@queensland-running/qr-components';

const HomeView: FunctionComponent = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <PageTitle title={
          // @ts-ignore
          `Hi, ${user.attributes['given_name']}`
        }/>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea href={Routes[RouteKeys.profile]} style={{ paddingTop: '66.66%', position: 'relative' }}>
                <img
                  width="100%"
                  style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                  src={'/assets/images/general/profile.jpg'}
                  alt=""
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" color="primary">
                    Your Profile
                  </Typography>
                  <Typography variant="body1" component="p">
                    View and manage your personal details
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" variant="contained" href={Routes[RouteKeys.profile]}>
                  Profile
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {user.roles.includes(ROLE.EVENT_MANAGER) && (
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea
                  href={Routes[RouteKeys.calendar]}
                  style={{ paddingTop: '66.66%', position: 'relative' }}>
                  <img
                    width="100%"
                    style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                    src={'/assets/images/general/calendar.jpg'}
                    alt=""
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" color="primary">
                      Calendar
                    </Typography>
                    <Typography variant="body1" component="p">
                      View and manage the calendar
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    href={Routes[RouteKeys.calendar]}>
                    Calendar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {user.roles.includes(ROLE.ADMINISTRATOR_MANAGER) && (
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea
                  href={Routes[RouteKeys.administrators]}
                  style={{ paddingTop: '66.66%', position: 'relative' }}>
                  <img
                    width="100%"
                    style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                    src={'/assets/images/general/volunteer.jpg'}
                    alt=""
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" color="primary">
                      Administrators
                    </Typography>
                    <Typography variant="body1" component="p">
                      View and manage Queensland Running administrators.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" variant="contained" href={Routes[RouteKeys.administrators]}>
                    Administrators
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {/*{user.roles.includes(ROLE.USER_MANAGER) && (*/}
          {/*  <Grid item xs={12} sm={6} md={4}>*/}
          {/*    <Card>*/}
          {/*      <CardActionArea href={Routes[RouteKeys.runners]} style={{ paddingTop: '66.66%', position: 'relative' }}>*/}
          {/*        <img*/}
          {/*          width="100%"*/}
          {/*          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}*/}
          {/*          src={'/assets/images/general/running-number.jpg'}*/}
          {/*          alt=""*/}
          {/*        />*/}
          {/*        <CardContent>*/}
          {/*          <Typography gutterBottom variant="h5" color="primary">*/}
          {/*            Runners*/}
          {/*          </Typography>*/}
          {/*          <Typography variant="body1" component="p">*/}
          {/*            View and manage Queensland Running runners.*/}
          {/*          </Typography>*/}
          {/*        </CardContent>*/}
          {/*      </CardActionArea>*/}
          {/*      <CardActions>*/}
          {/*        <Button size="small" color="primary" variant="contained" href={Routes[RouteKeys.runners]}>*/}
          {/*          Runners*/}
          {/*        </Button>*/}
          {/*      </CardActions>*/}
          {/*    </Card>*/}
          {/*  </Grid>*/}
          {/*)}*/}
        </Grid>

        <Grid container spacing={3}>
          <Grid item>
            <Typography>
              For general enquiries, issues/bugs and access, contact <Contact name={'jolsen'} type={'emailAddress'} />
            </Typography>
            <Typography>
              Should you require immediate assistance, contact <Contact name={'jolsen'} type={'phoneNumber'} />
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
};

export const Home = HomeView;
