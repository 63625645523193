import {compose} from 'recompose';
import {Button, Chip} from '@material-ui/core';
import React from 'react';
import {RockettRelays, WeeklyCompetition} from '@generated/schema';
import {RouteComponentProps} from 'react-router-dom';
import {mapRoute, RouteKeys, RouteLabels, Routes} from '@constants';
import {
    Calendar as CalendarComponent,
    ErrorComponent,
    Link,
    LoadingComponent,
    PageTitle
} from '@queensland-running/qr-components';
import {useCalendar} from '@components/higher-order/calendar';
import {CompetitionType} from "../../../types";
import {InfoTwoTone} from "@material-ui/icons";

type CalendarProps = RouteComponentProps;
type CalendarViewProps = CalendarProps;
type ParamProps = {
    year: string;
};

const enhance = compose<CalendarViewProps, CalendarProps>(React.memo);

const renderAction = (props: WeeklyCompetition | RockettRelays & { __typename: string }) => {
    // const isHoliday = props.__typename === 'No Competition';
    // if (isHoliday) return null;
    const {
        id,
        day,
        status,
        venueId,
        results
    } = props;
    const eventHasPassed = new Date() > new Date(day);
    return (
        <>
            <Link to={`${Routes[RouteKeys.competition]}?competitionId=${id}`}>
                <Button color="primary" variant="contained" fullWidth>
                    Details
                </Button>
            </Link>
            {venueId !== 'No Competition' && !results && eventHasPassed && status !== 'CANCELLED' && (
                <Chip
                    style={{marginTop: '0.5rem'}}
                    size="small"
                    icon={<InfoTwoTone fontSize="small"/>}
                    label="NO RESULTS"
                    color="secondary"
                />
            )}
            {status && (
                <Chip
                    style={{marginTop: '0.5rem'}}
                    label={status.replace(/_/g, ' ')}
                    variant="outlined"
                    size="small"
                    color={status === 'CANCELLED' ? 'primary' : 'secondary'}
                />
            )}
        </>
    );
};

const CalendarView = () => {
    const {calendar, loading, error, currentYear, setYear, availableYears} = useCalendar();

    const onHandleYearChange = ({target: {value}}: React.ChangeEvent<{ value: unknown }>) => {
        setYear((value as string) ?? 2024);
    };
    if (loading) {
        return <LoadingComponent/>;
    }

    if (error) {
        return <ErrorComponent/>;
    }

    const {events} = calendar;

    return (
        <>
            <PageTitle title={RouteLabels.calendar}/>
            <CalendarComponent
                onChangeYear={onHandleYearChange}
                availableYears={availableYears}
                selectedYear={currentYear}
                competitions={events as CompetitionType[]}
                onClickVenueRouter={(venue: string) => mapRoute(venue)}
                action={renderAction}
                onClickAddEvent={() => {
                }}
                isAdmin={true}
            />
        </>
    );
};

export const Calendar = enhance(CalendarView);
